import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/components/product.scss"

const Product = product => {

    const listing = product.pageContext.product;

    return (
        <Layout template="product-listing">
            <Seo 
                title={ listing.title } 
                description={ listing.seo_desc } 
                publishDate= { listing.publish_date }
                modDate= { listing.mod_date }
            /> 
            <section
                className={ `product-listing content-section bg-color-navy` }
                itemScope
                itemType={ `https://schema.org/Product` }>
                
                <div 
                className={ `container-xxl inner` }>
                    <div className="back">
                        <Link className={ `button button-sm` } to="/store">← Back</Link>
                    </div>
                    <h1 
                    className={ `page-title` }
                    itemProp={ `name` }>
                        { listing.title }
                    </h1>

                    <figure 
                    className={ `feature-image`}>

                        { listing.image &&
                            <img
                                src={ listing.image }
                                alt={ listing.title}
                                className={ `image` }
                                width={ `438` }
                                height={ `250` }
                                itemProp={ `image` }
                            /> }
                    </figure>
                        
                    <div 
                    className={ `product-details` }>
                        <div 
                            className={ `offer` } 
                            itemProp="offers" 
                            itemScope 
                            itemType="https://schema.org/Offer"
                        >
                            <meta itemProp={ `itemCondition` } content={ `https://schema.org/UsedCondition` } />

                            { listing.available === true
                                ? <div className={ `availability available` } itemProp={ `availability` } content={ `https://schema.org/InStock` }>Currently Available</div>
                                : <div className={ `availability not-available` } itemProp={ `availability` } content={ `https://schema.org/SoldOut` }>Not Available</div>
                            }

                            { listing.price === "Private Collection"
                                ?   <div className={ `adoption-fee` }>
                                        <span className={ `label` }>Price: </span>
                                        <span itemProp={ `price` } content={ listing.price }>{ listing.price }</span>
                                    </div>
                                :   <div className={ `adoption-fee` }>
                                        <span className={ `label` }>Price: </span>
                                        <span itemProp={ `priceCurrency` } content="USD">$</span>
                                        <span itemProp={ `price` } content={ listing.price }>{ listing.price }</span>
                                    </div>
                            }
                        </div>

                        { listing.description &&
                            <div 
                            className={ `description` } 
                            dangerouslySetInnerHTML={{__html: listing.description  }}
                            itemProp={ `description` }> 
                            </div> 
                        }

                        { listing.specs &&
                            <div 
                            className={ `specs` }>
                                <hr />
                                <h2 dangerouslySetInnerHTML={{__html: `Details`  }}></h2>
                                <ul className={ `specs-list` }>
                                    { listing.specs.map( (spec) =>
                                    <li 
                                        key={ `${spec.label}-${spec.spec}` }
                                    >
                                        { spec.label ? <strong className="label">{ spec.label }: </strong> : '' }
                                        { Array.isArray(spec.spec) === true 
                                            ? <ul> { spec.value.map( (subspec) => <li key={ subspec }><span className="value" dangerouslySetInnerHTML={{__html: subspec}}></span></li> ) } </ul>
                                            : <span className="value" dangerouslySetInnerHTML={{__html: spec.spec}}></span>
                                        }
                                    </li>
                                    )} 

                                </ul> 
                            </div>
                        }
                        { listing.known_issues &&
                            <div className={ `issues` }>
                                <hr />
                                <h2 dangerouslySetInnerHTML={{__html: `Known Issues`  }}></h2>
                                <ul className={ `issues-list` }>
                                    { listing.known_issues.map( (issue) =>
                                        <li key={ issue }>
                                            <span className="value" dangerouslySetInnerHTML={{__html: issue }}></span>
                                        </li>
                                    )} 
                                </ul> 
                            </div>
                        }
                    </div>

                    { listing.gallery &&
                        <div className={ `gallery` }>
                            <hr />
                            <h2 dangerouslySetInnerHTML={{__html: `Image Gallery`  }}></h2>
                            <ul className={ `slider` }>
                                { listing.gallery.map( (image) =>
                                    <li className={ `slide` } key={ image }>
                                        <figure>
                                            <img
                                                src={ image }
                                                alt={ listing.title + (image.split(listing.slug)[image.split(listing.slug).length - 1 ].replace(/-/g, ' ')).replace('.webp', '') }
                                                placeholder="blurred"
                                                className={ `gallery-image` }
                                                height={ `200` }
                                                width={ `300` }
                                            />
                                            <span className={ `caption` }>
                                            { listing.title + (image.split(listing.slug)[image.split(listing.slug).length - 1 ].replace(/-/g, ' ')).replace('.webp', '') }
                                            </span>
                                        </figure>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }

                    {
                        <div className={ `listing-contact` }>
                            <h2>Contact</h2>
                            <p>If you are interested in <em>{listing.title}</em> please contact</p>
                            <img 
                                src={ `https://res.cloudinary.com/simplesaint/image/upload/fl_sanitize/ValleyGemProperties/contact-email-light.svg` } 
                                alt={ `Please contact us if you are interested in this item` } 
                                width={ `408` }
                                height={ `23` }
                            />
                        </div>
                    }
                </div>
            </section>
        </Layout>
    )
}

export default Product